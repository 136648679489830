import { Text } from "@clipboard-health/ui-react";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button, Paper } from "@mui/material";
import { useCreateAccountReactivationRequest } from "@src/appV2/AccountReactivation";
import { useAgentLoggedIn } from "@src/appV2/Agents/api/useAgentLoggedIn";
import { useLegacyStateContext } from "@src/appV2/LegacyStateBridge/context";
import {
  APP_V2_USER_EVENTS,
  extractPhoneNumberDigits,
  ProgressDialog,
  useToast,
} from "@src/appV2/lib";
import { APP_V2_APP_EVENTS, logError, logEvent } from "@src/appV2/lib/analytics";
// eslint-disable-next-line no-restricted-imports
import { getFirebaseSingleton } from "@src/lib/firebase";
import { useMutation } from "@tanstack/react-query";
import { type ReactElement } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Link, useHistory } from "react-router-dom";

import {
  LoginConfirmationDialog,
  PhoneNumberExistsDialog,
  SignUpInsteadDialog,
  SignUpInsteadDialogAuthMethod,
  VerifyOtpDialog,
} from "../Dialogs";
import { ReactivationRequiredDialog } from "../Dialogs/ReactivationRequiredDialog";
import { AuthFormContent, AuthMode, useAuthMode } from "../libs";
import { useAuthWorkflow } from "../libs/authUtils";
import { AuthMethod, type LoginSignUpFormFields } from "../types";
import { AuthFormFields } from "./FormFields";
import { getFormSchema } from "./schema";

export function LoginSignUpForm(): ReactElement {
  const {
    initiateLogin,
    initiateSignUp,
    otpRequestIsLoading,
    loginLinkRequestIsLoading,
    signUpInsteadPhoneModalState,
    signUpInsteadEmailModalState,
    otpModalState,
    emailConfirmationModalState,
    authLoadingMessage,
    phoneNumberExistsModalState,
    reactivationModalState,
    authMethod,
    setAuthMethod,
  } = useAuthWorkflow();

  const {
    mutateAsync: createAccountReactivationRequest,
    isLoading: isCreatingAccountReactivationRequest,
  } = useCreateAccountReactivationRequest();

  const history = useHistory();

  const { authMode, authFormContent, authModeIsLogin } = useAuthMode();
  const formMethods = useForm<LoginSignUpFormFields>({
    defaultValues: {
      phoneNumber: "",
      email: "",
    },
    resolver: zodResolver(getFormSchema(authMode, authMethod)),
  });
  const { formState, handleSubmit, watch } = formMethods;

  const phoneNumber = watch("phoneNumber");
  const email = watch("email");
  const { showErrorToast, showInfoToast } = useToast();

  const { mutateAsync: sendAgentLoggedIn } = useAgentLoggedIn();
  const {
    mutateAsync: onAuthenticateWithToken,
    isLoading: authenticationIsLoading,
    isSuccess: authenticationIsSuccessful,
  } = useMutation({
    mutationFn: async ({ token }: { token: string }) => {
      /**
       * Rely on appV1 firebase event listener to update auth state and redirect.
       * `getFirebaseSingleton().onAuthStateChanged` and `getFirebaseSingleton().onIdTokenChanged`
       * in v1 init code.
       */
      await getFirebaseSingleton().signInWithCustomToken(token);
    },
    onSuccess: async () => {
      if (authMode === AuthMode.LOGIN) {
        try {
          /**
           * In authV1, we fire (without async) a post to notify the server that we
           * are logged in. Ignore errors since this is just for profiling.
           */
          await sendAgentLoggedIn();
        } catch (error) {
          logError(APP_V2_APP_EVENTS.AGENT_PROFILE_LOGGED_IN_ERROR, { error });
        }
      }
    },
    onError: (error) => {
      showErrorToast("Failed to authenticate, please try again.");
      logError(APP_V2_APP_EVENTS.FIREBASE_OTP_SIGN_IN_ERROR, { error });
    },
  });

  const {
    session: { isAuthorized },
    setLegacyIsSignUp,
  } = useLegacyStateContext();

  return (
    <FormProvider {...formMethods}>
      <form
        onSubmit={handleSubmit(async (formData) => {
          try {
            setLegacyIsSignUp(authMode);
            switch (authMode) {
              case AuthMode.LOGIN: {
                if (authMethod === AuthMethod.PHONE) {
                  await initiateLogin({
                    phoneNumber: formData.phoneNumber,
                    email: "",
                  });

                  logEvent(APP_V2_USER_EVENTS.SUBMIT_LOGIN_FORM_WITH_PHONE_NUMBER, {
                    phoneNumber: formData.phoneNumber,
                  });
                } else {
                  await initiateLogin({
                    phoneNumber: "",
                    email: formData.email,
                  });
                  logEvent(APP_V2_USER_EVENTS.SUBMIT_LOGIN_FORM_WITH_EMAIL, {
                    email: formData.email,
                  });
                }

                return true;
              }

              case AuthMode.SIGN_UP: {
                await initiateSignUp(formData);
                logEvent(APP_V2_USER_EVENTS.SUBMIT_SIGNUP_FORM, {
                  phoneNumber: formData.phoneNumber,
                  email: formData.email,
                });
                return true;
              }

              default: {
                throw new Error(`Unknown AuthMode: ${authMode as string}`);
              }
            }
          } catch (error) {
            showErrorToast("Failed to log in.");
            logError(APP_V2_APP_EVENTS.AUTH_ON_SUBMIT_FAILURE, { error });
            return false;
          }
        })}
      >
        <Paper
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            borderRadius: 2,
            gap: {
              xs: 1,
              sm: 1,
              md: 2,
            },
            paddingX: {
              xs: 3,
              sm: 3,
              md: 4,
            },
            paddingY: {
              xs: 2,
              sm: 2,
              md: 3,
              lg: 4,
            },
          }}
        >
          <AuthFormFields authMethod={authMethod} />
          <Button
            variant="contained"
            type="submit"
            disabled={formState.isSubmitting}
            size="large"
            sx={{ marginTop: 2 }}
          >
            {authFormContent[AuthFormContent.SUBMIT_BUTTON]}
          </Button>
          {authModeIsLogin ? (
            <Button
              variant="outlined"
              onClick={() => {
                const newAuthMethod =
                  authMethod === AuthMethod.PHONE ? AuthMethod.EMAIL : AuthMethod.PHONE;

                logEvent(APP_V2_USER_EVENTS.SWITCH_LOGIN_METHOD, {
                  from: authMethod,
                  to: newAuthMethod,
                });

                setAuthMethod(newAuthMethod);
              }}
            >
              {authMethod === AuthMethod.PHONE ? "Login via email" : "Login via phone number"}
            </Button>
          ) : null}

          <Text textAlign="center">
            {authFormContent[AuthFormContent.SWITCH_AUTH_MODE_INSTRUCTIONS]}{" "}
            <Link
              to={authMode === AuthMode.LOGIN ? AuthMode.SIGN_UP : AuthMode.LOGIN}
              onClick={() => {
                // Reset to login by phone number when interchanging between Login/Sign Up
                setAuthMethod(AuthMethod.PHONE);

                formMethods.clearErrors();
                logEvent(APP_V2_USER_EVENTS.SWITCH_BETWEEN_LOGIN_SIGNUP, {
                  from: authMode,
                  to: authMode === AuthMode.LOGIN ? AuthMode.SIGN_UP : AuthMode.LOGIN,
                });
              }}
            >
              {authFormContent[AuthFormContent.SWITCH_AUTH_MODE_CTA]}
            </Link>
          </Text>
        </Paper>
      </form>

      <SignUpInsteadDialog
        modalState={signUpInsteadPhoneModalState}
        authMethod={SignUpInsteadDialogAuthMethod.PHONE}
      />
      <SignUpInsteadDialog
        modalState={signUpInsteadEmailModalState}
        authMethod={SignUpInsteadDialogAuthMethod.EMAIL}
      />

      <VerifyOtpDialog
        modalState={otpModalState}
        phoneNumber={phoneNumber}
        authMode={authMode}
        onSuccess={async (params) => {
          const { firebaseToken, agentDeletionRequestCancelled } = params;
          await onAuthenticateWithToken({ token: firebaseToken });

          if (agentDeletionRequestCancelled) {
            showInfoToast(
              "Your deletion request has been cancelled because you logged in. If you still wish to delete your account, please submit a new deletion request from the Profile page."
            );
          }

          logEvent(APP_V2_USER_EVENTS.SUBMIT_AUTH_OTP_FORM_SUCCESS);
        }}
      />
      <LoginConfirmationDialog modalState={emailConfirmationModalState} email={email} />

      <PhoneNumberExistsDialog
        modalState={phoneNumberExistsModalState}
        onLogin={() => {
          void initiateLogin({ phoneNumber, email: "" });
        }}
      />

      <ReactivationRequiredDialog
        modalState={reactivationModalState}
        isLoading={isCreatingAccountReactivationRequest}
        onConfirm={async () => {
          try {
            const phoneNumberDigits = extractPhoneNumberDigits(phoneNumber);
            const { data } = await createAccountReactivationRequest(
              authMethod === AuthMethod.EMAIL ? { email } : { phone: phoneNumberDigits }
            );

            if (authMethod === AuthMethod.EMAIL) {
              const params = new URLSearchParams({
                reactivationId: data.id,
                email,
                nextPath: `/v2/reactivation/phone?reactivationId=${data.id}`,
              });

              history.push(`/v2/reactivation/email?${params.toString()}`);
            } else {
              const params = new URLSearchParams({
                reactivationId: data.id,
                phoneNumber,
                nextPath: `/v2/reactivation/email?reactivationId=${data.id}`,
              });

              history.push(`/v2/reactivation/phone?${params.toString()}`);
            }
          } catch {
            showErrorToast(
              "Something went wrong while during account reactivation. Please contact support."
            );
          }
        }}
      />

      <ProgressDialog
        modalIsOpen={otpRequestIsLoading || loginLinkRequestIsLoading}
        messageContent={authLoadingMessage}
      />
      <ProgressDialog
        modalIsOpen={
          authenticationIsLoading ||
          /**
           * In addition to loading, we also need to show loading progress
           * for the firebase auth listeners, until we are authorized.
           */
          (authenticationIsSuccessful && !isAuthorized)
        }
        messageContent="Logging In..."
      />
    </FormProvider>
  );
}
