import { LoadingButton } from "@clipboard-health/ui-react";
import { useStepUpAuthContext } from "@src/appV2/Auth/LoginSignUp/StepUpAuth/context";
import { useToast } from "@src/appV2/lib";
import { type APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";

interface StepUpOtpButtonProps {
  isLoading: boolean;
  eventName: (typeof APP_V2_USER_EVENTS)[keyof typeof APP_V2_USER_EVENTS];
  phoneNumber: string;
  onSuccess: () => void;
}

export function StepUpAuthRequestOtpButton(props: StepUpOtpButtonProps) {
  const { isLoading, eventName, phoneNumber, onSuccess } = props;

  const {
    countdownIsLoading,
    startCountdownTimer,
    countdownSecondsLeft,
    isRequestingOtp,
    requestOtp,
  } = useStepUpAuthContext();

  const { showErrorToast } = useToast();

  return (
    <LoadingButton
      fullWidth
      isLoading={isLoading || isRequestingOtp}
      variant="contained"
      disabled={countdownIsLoading}
      aria-label="Send OTP"
      onClick={async () => {
        logEvent(eventName);

        if (countdownIsLoading) {
          return;
        }

        try {
          await requestOtp({
            isSignup: false,
            phone: phoneNumber,
          });

          startCountdownTimer();

          onSuccess();
        } catch {
          showErrorToast("Something went wrong while requesting an OTP code. Please try again.");
        }
      }}
    >
      {countdownIsLoading ? `Resend in ${countdownSecondsLeft}s` : "Send OTP code"}
    </LoadingButton>
  );
}
